import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'

import '@/api/axios.js'

import 'vant/lib/index.css'

import '@/assets/style/reset.css'

Vue.config.productionTip = false

Vue.use(Vant)

new Vue({
    router,
    render: (h) => h(App)
}).$mount('#app')
