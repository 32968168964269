import axios from 'axios'

// 配置全局axios请求前缀
// axios.defaults.baseURL = '/api'
axios.defaults.baseURL = 'http://ddns.thirdtec.cn:50206/'
axios.defaults.timeout = 5000

// 允许跨域
axios.defaults.withCredentials = true

// axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.request.use(
    function (config) {
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

// 配置response过滤器
axios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        return Promise.reject(error)
    }
)
