import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import('./views/Phospherus/index'),
            hidden: true
        },
        {
            path: '/result',
            name: 'result',
            component: () => import('./views/Phospherus/result'),
            hidden: true
        }
    ]
})
export default router
